'use client';
import pluralize from 'pluralize';
import { bseUserCart } from '../apis/api';
import { cartBseStorageName, userStorageName } from '../apis/config';
import { variantType } from '../types/product-types';
import { setLocalData, titleToSlug } from './cmnHelper';
import { R_PRODUCT } from './path';

export const isValidJSON = (data: string | null) => {
    if (data) {
        try {
            JSON.parse(data);
            return true;
        } catch (error) {
            return false;
        }
    }
};

export const getLocalStorage = (key: string) => {
    if (typeof window !== 'undefined') {
        const storedValue = localStorage.getItem(key);
        if (storedValue !== null && isValidJSON(storedValue)) {
            return JSON.parse(storedValue);
        }
    }
    return null;
};

export const updateUserCartRealTime = async (deliveryOption = 'regular') => {
    try {
        const data = await bseUserCart(deliveryOption);

        if (data?.status === 'success') {
            await new Promise<void>((resolve) => {
                setLocalData(data?.data, 'cart');
                resolve();
            });
            return data?.data;
        } else {
            // toast.error(data?.message);
            return false;
        }
    } catch (error) {
        console.error('Error updating user cart:', error);
    }
};

export const removeAllLocalStorageData = () => {
    try {
        typeof window !== 'undefined' && localStorage.clear();
        console.log('Local storage data cleared successfully.');
    } catch (error) {
        console.error('Error clearing local storage:', error);
    }
};
export const getDeviceType = () => {
    if (typeof window !== 'undefined') {
        const userAgent = navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return 'ISO';
        } else if (/Android/.test(userAgent)) {
            return 'ANDROID';
        }
    }
    return 'ANDROID';
};

export const b2bUser = () => {
    const user = getLocalStorage(userStorageName);
    return user?.u_pharmacy_id && user?.pharmacy_status === 'approved' ? true : false;
};

export const getUserCartFromLocalStorage = () => {
    const getData = typeof window !== 'undefined' ? localStorage.getItem(cartBseStorageName) : null;
    return getData && isValidJSON(getData) ? JSON?.parse(getData) : {};
};

//DO NOT USE IF COMPONENT IS NOT CLIENT
export const keyProductMrp = b2bUser() ? 'pv_b2b_mrp' : 'pv_b2c_mrp';
export const keyProductPrice = b2bUser() ? 'pv_b2b_price' : 'pv_b2c_price';
export const keyProductProfitPercentage = b2bUser() ? 'pv_b2b_profit_percent' : 'pv_b2c_profit_percent';
export const keyProductPuDefSalesUnitId = b2bUser() ? 'pu_b2b_sales_unit_id' : 'pu_b2c_sales_unit_id';

export function calculatePercentage(d_price: any, price: any) {
    return !!parseFloat(price) && !!parseFloat(d_price) ? (100 - (d_price / price) * 100).toFixed(0) : 0;
}

export const generateQtyList = (variant: variantType) => {
    if (!variant) {
        return [];
    }

    const {
        pv_b2b_min_qty,
        pv_b2c_min_qty,
        pv_b2b_max_qty,
        pv_b2c_max_qty,
        pu_multiplier,
        pu_unit_label,
        pu_b2b_base_unit_multiplier,
        pu_b2c_base_unit_multiplier,
        pu_b2b_sales_unit_label,
        pu_b2c_sales_unit_label,
        pu_base_unit_label
    } = variant;

    const minQty = b2bUser() ? pv_b2b_min_qty : pv_b2c_min_qty;
    const maxQty = b2bUser() ? pv_b2b_max_qty : pv_b2c_max_qty;
    let baseUnitMultiplier: any = b2bUser() ? pu_b2b_base_unit_multiplier : pu_b2c_base_unit_multiplier;
    let defaultSalesUnitLabel: any = b2bUser() ? pu_b2b_sales_unit_label : pu_b2c_sales_unit_label;

    if (!baseUnitMultiplier) {
        baseUnitMultiplier = pu_multiplier;
    }

    if (!defaultSalesUnitLabel) {
        defaultSalesUnitLabel = pu_unit_label;
    }

    return Array.from({ length: maxQty - minQty + 1 }, (_, i) => {
        const quantity = minQty + i;
        const isSameUnit = pu_base_unit_label === defaultSalesUnitLabel;
        const labels = ['tablet', 'capsule', 'injection'];
        const x = labels?.includes(pu_base_unit_label?.toLocaleLowerCase()) ? ' ' : ' x ';
        const renderLabel = () => {
            if (defaultSalesUnitLabel && baseUnitMultiplier) {
                if (isSameUnit) {
                    if (defaultSalesUnitLabel === "1's Pack") {
                        return `${quantity}${x}${defaultSalesUnitLabel}`;
                    }
                    if (labels?.includes(pu_base_unit_label?.toLocaleLowerCase())) {
                        return `${quantity}${x}${pluralize(defaultSalesUnitLabel, quantity)}`;
                    } else {
                        return `${quantity}${x}${defaultSalesUnitLabel}`;
                    }
                }
                // return `${quantity * baseUnitMultiplier}${x}${pluralize(pu_base_unit_label, quantity)} (${quantity} ${pluralize(
                //     defaultSalesUnitLabel,
                //     quantity
                // )})`;
                return `${quantity * baseUnitMultiplier}${x}${pluralize(
                    pu_base_unit_label,
                    quantity * baseUnitMultiplier
                )} (${quantity} ${pluralize(defaultSalesUnitLabel, quantity)})`;
            } else {
                return quantity;
            }
        };

        return {
            label: renderLabel(),
            value: quantity
        };
    });
};

export const generateSingleQtyList = (variant: variantType, crrQty: number) => {
    if (!variant) {
        return [];
    }

    const {
        // pv_b2b_min_qty,
        // pv_b2c_min_qty,
        // pv_b2b_max_qty,
        // pv_b2c_max_qty,
        pu_multiplier,
        pu_unit_label,
        pu_b2b_base_unit_multiplier,
        pu_b2c_base_unit_multiplier,
        pu_b2b_sales_unit_label,
        pu_b2c_sales_unit_label,
        pu_base_unit_label
    } = variant;

    // const minQty = b2bUser() ? pv_b2b_min_qty : pv_b2c_min_qty;
    // const maxQty = b2bUser() ? pv_b2b_max_qty : pv_b2c_max_qty;
    let baseUnitMultiplier: any = b2bUser() ? pu_b2b_base_unit_multiplier : pu_b2c_base_unit_multiplier;
    let defaultSalesUnitLabel: any = b2bUser() ? pu_b2b_sales_unit_label : pu_b2c_sales_unit_label;

    if (!baseUnitMultiplier) {
        baseUnitMultiplier = pu_multiplier;
    }

    if (!defaultSalesUnitLabel) {
        defaultSalesUnitLabel = pu_unit_label;
    }

    const quantity = crrQty ? crrQty * 1 : 1;
    const isSameUnit = pu_base_unit_label === defaultSalesUnitLabel;
    const labels = ['tablet', 'capsule', 'injection'];
    const x = labels?.includes(pu_base_unit_label?.toLocaleLowerCase()) ? ' ' : ' x ';

    const renderLabel = () => {
        if (defaultSalesUnitLabel && baseUnitMultiplier) {
            if (isSameUnit) {
                if (defaultSalesUnitLabel === "1's Pack") {
                    return `${quantity}${x}${defaultSalesUnitLabel}`;
                }
                if (labels?.includes(pu_base_unit_label?.toLocaleLowerCase())) {
                    return `${quantity}${x}${pluralize(defaultSalesUnitLabel, quantity)}`;
                } else {
                    return `${quantity}${x}${defaultSalesUnitLabel}`;
                }
            }
            return `${quantity * baseUnitMultiplier}${x}${pluralize(
                pu_base_unit_label,
                quantity * baseUnitMultiplier
            )} (${quantity} ${pluralize(defaultSalesUnitLabel, quantity)})`;
        } else {
            return quantity;
        }
    };
    return renderLabel();
};

type link = {
    product: any;
    varId?: number;
    back_prev_page?: string;
};

export const generateProductUrl = (data: link) => {
    const { product, varId, back_prev_page } = data;

    if (!product) {
        return '/';
    }

    const { p_id } = product;
    const productSlug = titleToSlug(product);

    const queryParams: any = {
        pv_id: varId || '',
        back_prev_page: back_prev_page || ''
    };

    const queryString = new URLSearchParams(queryParams).toString();

    return `${R_PRODUCT}/${p_id}/${productSlug}?${queryString}`;
};

export const ClientLogTest = ({ data, payload }: any) => {
    console.log('clientLogTest: ', data);
    if (payload) {
        console.log('clientLogTest payload: ', payload);
    }
    return '';
};

export const ClientNoCache = ({ data, payload }: any) => {
    // console.log('clientLogTest: ', data);
    // if (payload) {
    //     console.log('clientLogTest payload: ', payload);
    // }
    return '';
};

export const findUnitLabel = (productUnit: any) => {
    if (Array.isArray(productUnit)) {
        const foundB2B = productUnit?.find((d: any) => !!d?.pu_is_b2b_sales_unit);
        const foundB2C = productUnit?.find((d: any) => !!d?.pu_is_b2c_sales_unit);
        return {
            b2b: { pu_label: foundB2B?.pu_label, pu_multiplier: foundB2B?.pu_multiplier },
            b2c: { pu_label: foundB2C?.pu_label, pu_multiplier: foundB2C?.pu_multiplier }
        };
    } else {
        return {
            b2b: { pu_label: productUnit?.pu_label, pu_multiplier: productUnit?.pu_multiplier },
            b2c: { pu_label: productUnit?.pu_label, pu_multiplier: productUnit?.pu_multiplier }
        };
    }
};

// interface ShareType {
//     platform?: 'facebook' | 'messenger' | 'twitter' | 'whatsapp';
//     title?: string;
//     text?: string;
// }
// export const handleSharePlatform = ({ platform, title, text }: ShareType) => {
//     if (typeof window !== undefined) {
//         const pathName = window.location.href;
//         let shareLink = '';

//         if (platform) {
//             switch (platform) {
//                 case 'facebook':
//                     shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pathName)}`;
//                     break;
//                 case 'messenger':
//                     shareLink = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(pathName)}`;
//                     break;
//                 case 'twitter':
//                     shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(pathName)}&text=Arogga Blog&via=arogga`;
//                     break;
//                 case 'whatsapp':
//                     shareLink = `https://wa.me/?text=${encodeURIComponent(`Arogga Blog: ${pathName}`)}`;
//                     break;
//                 default:
//                     toast.error('Invalid platform');
//             }
//         }
//         const shareOptions: any = {
//             title: title || 'Arogga',
//             url: pathName
//         };
//         if (text) {
//             shareOptions.text = text;
//         }

//         if (navigator?.share && !platform) {
//             navigator
//                 .share(shareOptions)
//                 .then(() => console.log('Successful share'))
//                 .catch((error) => console.error('Error sharing:', error));
//         } else if (shareLink) {
//             window.open(shareLink, '_blank');
//         }
//     }
// };
