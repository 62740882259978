'use client';
import { logEvent } from 'firebase/analytics';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { MIXPANEL_TOKEN, PIXELID } from '../apis/config';
import { firebaseAnalytics } from './firebase';

import mixpanel from 'mixpanel-browser';
import useAuth from '../hooks/use-auth';

const useAnalytics = () => {
    const pathname = usePathname();
    const { user } = useAuth();

    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                if (PIXELID) {
                    ReactPixel.init(PIXELID);
                    ReactPixel.pageView();
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    }, [pathname]);

    useEffect(() => {
        // Mixpanel Initialization
        mixpanel.init(MIXPANEL_TOKEN as string, {
            track_pageview: 'full-url',
            debug: false
        });

        if (user?.u_id) {
            mixpanel.identify(user?.u_id.toString());
            mixpanel.people.set({
                $distinct_id: user?.u_id,
                $name: user?.u_name,
                $phone: user?.u_mobile
            });
            mixpanel.register({
                user_id: user?.u_id,
                user_name: user?.u_name,
                user_mobile: user?.u_mobile
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, user?.u_id]);

    const fbEvent = (eventName: string, eventParams: any) => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                if (PIXELID) {
                    ReactPixel.init(PIXELID);
                    ReactPixel.track(eventName, eventParams);
                }
            });
    };

    const firebaseEvent = (eventName: string, eventParams: any) => {
        if (firebaseAnalytics) {
            logEvent(firebaseAnalytics, eventName, eventParams);
        }
    };

    const mixpanelEvent = (eventName: string, eventParams: Record<string, any>) => {
        if (mixpanel) {
            mixpanel.track(eventName, eventParams);
        } else {
            console.warn('Mixpanel is not initialized');
        }
    };

    const claverTapEvent = (eventName: string, eventParams: Record<string, any>) => {
        if (typeof window !== 'undefined' && window?.clevertap) {
            window?.clevertap.event.push(eventName, eventParams);
        } else {
            console.warn('CleverTap is not initialized');
        }
    };

    return { fbEvent, firebaseEvent, claverTapEvent, mixpanelEvent };
};

export default useAnalytics;
