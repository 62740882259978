import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyBUw_yCiMIm1opts7rgjwCIPSJwvD6-RUo',
    authDomain: 'arogga.firebaseapp.com',
    databaseURL: 'https://arogga.firebaseio.com',
    projectId: 'arogga',
    storageBucket: 'arogga.appspot.com',
    messagingSenderId: '741928054946',
    appId: '1:741928054946:web:4246ba735037cc28c0a7fb',
    measurementId: 'G-9VH8P5RZDM'
};

const app = initializeApp(firebaseConfig);
export const firebaseAnalytics = typeof window != 'undefined' ? getAnalytics(app) : null;
