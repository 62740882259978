'use client';

import { useState, useEffect } from 'react';
import { userStorageName } from '../apis/config';
import { UserTypes } from '../types/user-types';
import { isValidJSON } from '../utils/clientHelper';

interface Auth {
    user: UserTypes | null;
}

const useAuth = (): Auth => {
    const [user, setUser] = useState<UserTypes | null>(null);
    const data = typeof window !== 'undefined' ? localStorage.getItem(userStorageName) : null;

    useEffect(() => {
        if (data && isValidJSON(data)) {
            setUser(JSON.parse(data));
        }
    }, [data]);

    return { user };
};

export default useAuth;
