import { usePathname } from 'next/navigation';

import { APP_URL } from '../apis/config';

import useAnalytics from './useAnalytics';

export const useLabTestPixelEvent = () => {
    const { fbEvent, firebaseEvent, mixpanelEvent } = useAnalytics();
    const pathname = usePathname();

    const LabTestAddToCartEvent = (payload: any) => {
        const { testData, patientCount } = payload;

        fbEvent('AddToCart', {
            content_ids: [testData?.id.toString()],
            content_name: testData?.name?.en,
            content_type: 'm_web_lab_test',
            contents: [
                {
                    id: testData?.id.toString(),
                    quantity: patientCount,
                    ...testData
                }
            ],
            currency: 'BDT',
            value: testData?.discountPrice
        });

        firebaseEvent('add_to_cart', {
            content_id: testData?.id.toString(),
            content_name: testData?.name?.en,
            content_type: 'm_web_lab_test',
            items: [
                {
                    item_id: testData?.id,
                    item_name: testData?.name?.en,
                    price: testData?.discountPrice
                }
            ],
            currency: 'BDT',
            value: testData?.discountPrice
        });

        mixpanelEvent('add_to_cart', {
            content_type: 'm_web_lab_test',
            content_id: testData?.id,
            content_name: testData?.name?.en,
            value_to_sum: parseInt(testData?.discountPrice) * parseInt(patientCount),
            quantity: patientCount,
            price: parseInt(testData?.discountPrice).toFixed(0)
        });
    };

    const LabTestViewContentEvent = (payload: any) => {
        fbEvent('view_item', {
            content_ids: [payload?.id?.toString()],
            content_name: payload?.name?.en,
            content_type: 'm_m_web_lab_test',
            contents: [
                {
                    ...payload,
                    quantity: 1
                }
            ],
            currency: 'BDT',
            value: payload?.discountPrice
        });

        firebaseEvent('view_item', {
            content_ids: payload?.id?.toString(),
            content_name: payload?.name?.en,
            content_type: 'm_m_web_lab_test',
            items: [
                {
                    item_id: payload?.id,
                    item_name: payload?.name?.en,
                    price: payload?.discountPrice,
                    quantity: 1
                }
            ],
            currency: 'BDT',
            value: payload?.discountPrice
        });

        mixpanelEvent('view_item', {
            content_type: 'm_web_lab_test',
            content_id: payload?.id?.toString(),
            content_name: payload?.name?.en,
            value: parseInt(payload?.discountPrice).toFixed(0)
        });
    };

    const LabTestSearchEvent = (payload: any) => {
        fbEvent('Search', {
            search_string: payload.search,
            content_type: 'm_web_lab_test'
        });
        firebaseEvent('search', {
            search_term: payload.search,
            content_type: 'm_web_lab_test'
        });
        mixpanelEvent('search_term', {
            search_term: payload.search,
            content_type: 'm_web_lab_test'
        });
    };

    const LabTestCheckoutEvent = (payload: any) => {
        fbEvent('InitiateCheckout', {
            content_type: 'm_web_lab_test',
            content_ids: payload?.items?.map((e: any) => e?.item?.id.toString()),
            contents: payload?.items?.map((e: any) => {
                return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
            }),
            currency: 'BDT',
            num_items: payload?.itemCount,
            value: payload?.isHardCopyRequired ? payload?.totalAmount : payload?.totalAmountWithoutConveyance
        });

        firebaseEvent('begin_checkout', {
            content_type: 'm_web_lab_test',
            currency: 'BDT',
            items: payload?.items,
            num_items: payload?.itemCount,
            value: payload?.isHardCopyRequired ? payload?.totalAmount : payload?.totalAmountWithoutConveyance
        });

        mixpanelEvent('initiate_checkout', {
            content_type: 'm_web_lab_test',
            contents: payload?.items?.map((e: any) => {
                return { item_id: e?.item?.id, quantity: e?.patientCount };
            }),
            coupon: payload?.promoCode,
            hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
            value: payload?.subtotalAmount
        });
    };

    const LabTestOrderPurchaseEvent = (payload: any) => {
        fbEvent('Purchase', {
            content_type: 'm_web_lab_test',
            content_ids: payload?.items?.map((e: any) => e?.id.toString()),
            contents: payload?.items?.map((e: any) => {
                return { ...e, id: e?.id, quantity: e?.patientCount };
            }),
            currency: 'BDT',
            num_items: payload?.itemCount,
            value: payload?.totalAmount
        });

        firebaseEvent('purchase', {
            content_type: 'm_web_lab_test',
            currency: 'BDT',
            items: payload?.items,
            num_items: payload?.itemCount,
            value: payload?.totalAmount
        });

        mixpanelEvent('purchase', {
            content_type: 'm_web_lab_test',
            contents: payload?.items?.map((e: any) => {
                return { ...e?.item, id: e?.item?.id, quantity: e?.patientCount };
            }),
            order_id: payload?.orderNumber,
            schedule_date: payload?.formattedScheduleDate,
            schedule_time: payload?.scheduleTimeRange?.en,
            payment_method: payload?.paymentMethod,
            content_count: payload?.itemCount,
            coupon: payload?.promoCode,
            hard_copy: payload?.isHardCopyRequired ? 'enable' : 'off',
            value: payload?.isHardCopyRequired ? parseInt(payload?.totalAmount).toFixed(0) : parseInt(payload?.totalAmount).toFixed(0)
        });
    };

    // LabTest Banner Events

    const LabTestBannerClickEvent = (payload: any) => {
        fbEvent('banner_action', {
            content_type: 'm_web_lab_test',
            banner_url: payload?.bannerUrl,
            redirectUrl: payload?.redirectUrl,
            campaign_id: payload?.campaignId,
            campaignName: payload?.campaignName
        });
        firebaseEvent('banner_action', {
            content_type: 'm_web_lab_test',
            banner_url: payload?.bannerUrl,
            redirectUrl: payload?.redirectUrl,
            campaign_id: payload?.campaignId,
            campaignName: payload?.campaignName
        });

        mixpanelEvent('banner_action', {
            content_type: 'm_web_lab_test',
            banner_url: payload?.bannerUrl,
            redirectUrl: payload?.redirectUrl,
            campaign_id: payload?.campaignId,
            campaignName: payload?.campaignName
        });
    };

    const LabTestBlockClickEvent = (payload: any) => {
        fbEvent('block_action', {
            content_type: 'm_web_lab_test',
            block_name: payload?.blockName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });
        firebaseEvent('block_action', {
            content_type: 'm_web_lab_test',
            block_name: payload?.blockName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });

        mixpanelEvent('block_action', {
            content_type: 'm_web_lab_test',
            block_name: payload?.blockName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });
    };

    const LabTestCategoryClickEvent = (payload: any) => {
        fbEvent('category_action', {
            content_type: 'm_web_lab_test',
            category_name: payload?.categoryName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });
        firebaseEvent('category_action', {
            content_type: 'm_web_lab_test',
            category_name: payload?.categoryName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });

        mixpanelEvent('category_action', {
            content_type: 'm_web_lab_test',
            category_name: payload?.categoryName,
            content_name: payload?.contentName,
            content_id: payload?.contentId,
            redirect_url: payload.redirectUrl,
            price: payload?.price
        });
    };

    const LabTestBannerToAddToCartEvent = (payload: any) => {
        const { testData, patientCount } = payload;

        fbEvent('AddToCart', {
            content_ids: [testData?.id.toString()],
            content_name: testData?.name?.en,
            content_type: 'm_web_lab_test',
            contents: [
                {
                    id: testData?.id.toString(),
                    quantity: patientCount,
                    ...testData
                }
            ],
            currency: 'BDT',
            value: parseInt(testData?.discountPrice).toFixed(0)
        });

        firebaseEvent('add_to_cart', {
            content_ids: [testData?.id.toString()],
            content_name: testData?.name?.en,
            content_type: 'm_web_lab_test',
            items: [
                {
                    item_id: testData?.id,
                    item_name: testData?.name?.en,
                    price: parseInt(testData?.discountPrice).toFixed(0)
                }
            ],
            currency: 'BDT',
            value: parseInt(testData?.discountPrice).toFixed(0)
        });

        mixpanelEvent('add_to_cart', {
            content_type: 'm_web_lab_test',
            content_id: testData?.id,
            content_name: testData?.name?.en,
            item: {
                ...testData,
                quantity: patientCount
            }
        });
    };

    return {
        LabTestAddToCartEvent,
        LabTestViewContentEvent,
        LabTestSearchEvent,
        LabTestCheckoutEvent,
        LabTestOrderPurchaseEvent,

        // Banner Events
        LabTestBannerClickEvent,
        LabTestBlockClickEvent,
        LabTestCategoryClickEvent
    };
};
